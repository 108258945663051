<template>
  <div>
    <!-- Brand logo-->
    <b-link :to="{ name: 'locations' }" class="brand-logo-nav">
      <!-- <vuexy-logo />-->
      <h2 v-if="title" class="brand-text text-primary ml-1">
        {{ title }}
      </h2>
      <img
        height="36"
        :src="image ? image : require('@/assets/images/logo/large_logo.svg')"
      />
    </b-link>
  </div>
</template>

<script>
import { BLink, BRow } from 'bootstrap-vue';
export default {
  props: {
    title: {
      type: String,
    },
    image: {
      type: String,
    },
  },
  components: {
    BLink,
    BRow,
  },
};
</script>

<style lang="scss">
.brand-logo-nav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2rem 0;
  .brand-text {
    font-weight: 600;
    margin-bottom: 0px;
  }

  margin: 0 40px;
  margin-bottom: 0px;
}
</style>
