<template>
  <div class="navbar-container main-menu-content h-100">
    <b-row class="h-100">
      <logo
        class="h-100"
        :image="require('@/assets/images/logo/large_logo.svg')"
      ></logo>
      <!--<horizontal-nav-menu-items :items="navMenuItems" />-->
      <!--<profile-dropdown></profile-dropdown>-->
      <div class="nav align-items-center ml-auto mr-3 h-100"></div>
      <b-button
        v-if="$store.state.auth.loggedInAs"
        variant="outline-primary"
        class="mt-1 mb-1"
        @click="loggedOutAs"
        >Izpiši se iz profila
        {{
          $store.state.auth.userData.firstname +
            ' ' +
            $store.state.auth.userData.lastname
        }}</b-button
      >
      <horizontal-nav-menu-items  class="h-100" :items="items" />
    </b-row>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal';
import { useNavItems } from '@/navigation/profile/settings';
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';
import { BCard, BCardText, BLink, BRow, BCol, BButton } from 'bootstrap-vue';
import ProfileDropdown from '../../../components/app-navbar/components/ProfileDropdown.vue';
import {
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue';
import Logo from '@/views/components/Logo.vue';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    HorizontalNavMenuItems,
    BRow,
    BCol,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Logo,
    ProfileDropdown,
    BButton,
  },
  setup() {
    const{
     items
    } =  useNavItems();

    return {
      navMenuItems,
      items,
    };
  },
  methods: {
    async loggedOutAs() {
      //we need to set super admin token
      useJwt.setToken(this.$store.state.auth.loggedInAs.token);
      const response = await this.$store.dispatch('auth/LOGIN_AS', {
        userId: this.$store.state.auth.loggedInAs.userId,
      });
      const userData = response.data;

      this.$store.commit('auth/SET_USER_DATA', userData);
      this.$ability.update(userData.ability);
      this.$i18n.locale = userData.lang;
      this.$store.commit('auth/SET_LOGGED_IN_USER', null);
      this.$router.go({ name: 'locations' });
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
