<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderGroup(item)"
    class="dropdown nav-item margin-right-20 h-100"
    :class="{
      'sidebar-group-active active open': isActive,
      show: isOpen,
    }"
    @mouseenter="() => updateGroupOpen(true)"
    @mouseleave="() => updateGroupOpen(false)"
  >
    <b-link
      class="nav-link dropdown-toggle d-flex align-items-center no-active h-100"
    >
      <feather-icon size="14" :icon="item.icon" />
      <div class="margin-right-20">
        <span class="user-name font-weight-bolder mb-0">{{ item.header }}</span
        ><br />
        <span v-if="item.subtitle" class="user-status">{{
          t(item.subtitle)
        }}</span>
      </div>
    </b-link>
    <ul class="dropdown-menu dropdown-options">
      <component
        :is="resolveHorizontalNavMenuItemComponent(child)"
        v-for="child in item.children"
        :key="child.title"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import { resolveHorizontalNavMenuItemComponent } from '@core/layouts/utils';
import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useHorizontalNavMenuHeaderGroup from './useHorizontalNavMenuHeaderGroup';
import mixinHorizontalNavMenuHeaderGroup from './mixinHorizontalNavMenuHeaderGroup';

import HorizontalNavMenuGroup from '../horizontal-nav-menu-group/HorizontalNavMenuGroup.vue';
import HorizontalNavMenuLink from '../horizontal-nav-menu-link/HorizontalNavMenuLink.vue';

export default {
  components: {
    BLink,
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive,
      updateIsActive,
      isOpen,
      updateGroupOpen,
    } = useHorizontalNavMenuHeaderGroup(props.item);

    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils();

    return {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      resolveHorizontalNavMenuItemComponent,

      // ACL
      canViewHorizontalNavMenuHeaderGroup,

      // i18n
      t,
    };
  },
};
</script>
<style scoped>
.user-status {
  font-size: smaller;
}
.user-name {
  float: right;
}
.dropdown-options {
  margin-left: -140px;
}
.margin-right-20 {
  margin-right: 20px;
}
.no-active {
  background: transparent !important;
  box-shadow: none !important;
  color: #6e6b7b !important;
}
</style>
