import { getUserData, isCompanyModuleActive } from '@/auth/utils';
import store from '@/store';

import useAppConfig from '@core/app-config/useAppConfig';
import { computed } from '@vue/composition-api';
const { isLocalLayout } = useAppConfig();

const vivoziteRoutes = () =>
  isLocalLayout.value
    ? []
    : [
        {
          title: 'Speed monitor',
          icon: 'FileTextIcon',
          children: [
            {
              title: 'List',
              route: {
                name: 'speeding-list',
                params: {
                  type: 'speeding',
                },
              },
              resource: 'Device',
            },
            {
              title: 'Iframe',
              route: {
                name: 'iframe-list',
                params: {
                  type: 'speeding',
                },
              },
              disabled: false,
              resource: 'Device',
            },
          ],
        },
        {
          title: 'Cyclist counter',
          icon: 'BarChart2Icon',
          disabled: false,
          children: [
            {
              title: 'List',
              route: {
                name: 'cyclist-list',
                params: {
                  type: 'cyclist',
                },
              },
              resource: 'Device',
            },
            {
              title: 'Iframe',
              route: {
                name: 'iframe-list',
                params: {
                  type: 'cyclist',
                },
              },
              disabled: false,
              resource: 'Device',
            },
          ],
        },
        {
          title: 'Vehicle counter',
          icon: 'TruckIcon',
          disabled: false,
          children: [
            {
              title: 'List',
              route: {
                name: 'vehicle-list',
                params: {
                  type: 'vehicle',
                },
              },
              resource: 'Device',
            },
            {
              title: 'Iframe',
              resource: 'Device',
              route: {
                name: 'error-404',
              },
            },
          ],
        },
      ];

export const useNavItems = () => {
  const items = computed(() => {
    return [
      {
        icon: '',
        header:
          store.state.auth.userData?.firstname +
          ' ' +
          store.state.auth.userData?.lastname,
        subtitle: store.state.auth.userData?.role,
        children: [
          {
            icon: 'UserIcon',
            route: 'profile',
            title: 'Profile',
            resource: 'Basic',
            action: 'read',
          },
          {
            icon: 'LogOutIcon',
            route: 'logout',
            title: 'Logout',
            underlined: true,
            resource: 'Basic',
            action: 'read',
          },
          ...vivoziteRoutes(),
          {
            title: 'Radars',
            icon: 'SpeakerIcon',
            children: [
              {
                title: 'List',
                resource: 'Device',
                action: 'read',
                route: {
                  name: 'radar-list',
                  params: {
                    type: 'radar',
                  },
                },
              },
              {
                title: 'Ftp credentials',
                resource: 'Device',
                route: {
                  name: 'ftp-credentials',
                },
              },
            ],
          },
          {
            title: 'General',
            icon: 'SettingsIcon',
            children: [
              {
                title: 'Companies',
                route: {
                  name: 'companies',
                },
              },
              {
                title: 'Users',
                route: {
                  name: 'users',
                },
                resource: 'User',
                action: 'manage',
              },
              {
                title: 'Groups',
                route: {
                  name: 'groups',
                  params: {
                    companyId: getUserData() ? getUserData().companyId : null,
                  },
                },
              },
              {
                title: 'LDAP',
                disabled: false,
                route: {
                  name: 'ldap',
                },
              },
              {
                title: 'Login history',
                disabled: false,
                route: {
                  name: 'audit-log',
                },
              },
            ],
          },
        ],
      },
    ];
  });

  return { items };
};
